<template>

  <div>
    <!-- <div class="tb">

           <i class="iconfont icon-fanhui"></i></router-link> 
        </div> -->
    <div class="second_return_icon margin_20">
      <router-link to="/system/service/workbench"><i class="iconfont icon-fanhui"></i> </router-link>
    </div>
    <div class="second_title_box">
      <span class="second_title1">
        评级服务订单详情
      </span>
    </div>
    <el-card class="orderdetail">
      <div class="orderdetail_main_header">
        <div class="orderdetail_orderscentre">订单中心</div>
        <el-button class="orderdetail_anniu" @click="tijiao()" v-show="submitButton">提交</el-button>
        <el-button class="orderdetail_annius" v-show="!submitButton">提交</el-button>
      </div>
      <el-form class="orderdetail_form" :model="from">
        <div class="orderdetail_form_nav">
          <el-form-item label="订单编号:" id="ddbh">
            <el-input disabled v-model="from.orderNum"></el-input>
          </el-form-item>
          <div id="nav">
            <el-form-item label="公司名称:">
              <el-input disabled v-model="from.subjectName"></el-input>
            </el-form-item>
          </div>
          <!-- <div id="nav">
                        <el-form-item label="订单项目:">
                            <el-input disabled></el-input>
                        </el-form-item>
                    </div> -->
          <div id="nav">
            <el-form-item label="完成日期:">
              <el-input disabled v-model="from.finishDate"></el-input>
            </el-form-item>
          </div>
          <div class="scbg add_invoice_messages">
            <el-form-item label="上传报告:" v-show="uploadFormShow">
              <el-upload ref="uploadForm" action="fakeaction1" list-type="picture-card" v-model="file" :http-request="handleUploadFile1" class="upload-demo" accept=".excal,.xlsx,.docx,.xls">
                <div class="upload_box">
                  <i class="el-icon-plus"></i>
                </div>
                <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
              </el-upload>
            </el-form-item>
            <div v-show="file&&uploadFormShow" class="invoiceUrlClass">
              <ul>
                <li v-for="(item,index) in file" :key="index" class="flex_center" style="position:relative">
                  <img :src="jpg + item">
                  <div class="close_icon" @click="deleteUpload(index)">
                    <i class="iconfont icon-yuyinguanbi"></i>
                  </div>
                </li>
              </ul>
            </div>
            <el-form-item label="上传报告:" v-show="!uploadFormShow" class="reportUrl_class">
              <ul class="invimg_list flex_wrap">
                <li v-for=" (item,index) in files" :key="index" class="flex_center">
                  <img :src="jpg + item">
                </li>
              </ul>
            </el-form-item>
            <!-- <div> -->
            <!-- <el-button v-show="!uploadFormShow" class="download_button flex_center" @click="loadingFile()">
                                <i class="iconfont icon-xiazai"></i>
                                <span>下载报告</span>
                            </el-button> -->
            <!-- </div> -->
          </div>
          <div class="pjjg">
            <el-form-item label="评级结果:" v-show="disabledShow">
              <el-input type="textarea" placeholder="" v-model="from.ratResult" maxlength="200" show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="评级结果:" v-show="!disabledShow">
              <el-input type="textarea" placeholder="" v-model="from.ratResult" maxlength="200" show-word-limit disabled>
              </el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-card>
    <el-card class="a">
      <div class="heard">
        <div class="gdpgzx">
          <span>更多评估咨询：</span>
          <el-input class="gdpgzx_select" disabled v-model="MoreConsultation"></el-input>
        </div>
        <div class="year">
          <span style="maging-left:-100px">年份：</span>
          <el-input class="date-picker-icon" disabled v-model="formLabelAligns.years"></el-input>
        </div>
      </div>
      <div class="dk2">
        <div class="dk3">
          <div class="dk3_1">
            <div class="from3">
              <el-form :label-position="labelPosition" :model="formLabelAlign" class="f">
                <div class="fl">
                  <div class="topbutton">
                    <el-form-item label="流动资产合计:">
                      <el-input v-model="formLabelAlign.totalCurrentAssets" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <!-- <div class="topbutton2">  -->
                  <el-form-item label="货币资金:">
                    <el-input v-model="formLabelAlign.monetaryCapital" class="mc" disabled></el-input>
                  </el-form-item>
                  <!-- </div> -->
                  <div class="topbutton2">
                    <el-form-item label="应收账款:">
                      <el-input v-model="formLabelAlign.accountsReceivable" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="预计款项:">
                      <el-input v-model="formLabelAlign.advancePayment" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="存货:">
                      <el-input v-model="formLabelAlign.stock" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="待摊费用:">
                      <el-input v-model="formLabelAlign.unamortizedExpense" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </div>
            <div class="from3">
              <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" class="f">
                <div class="fl f1_class1">
                  <div class="topbutton">
                    <el-form-item label="负债总额:">
                      <el-input v-model="formLabelAlign.totalCurrentLiability" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <!-- <div class="topbutton2">  -->
                  <el-form-item label="流动负债合计:">
                    <el-input v-model="formLabelAlign.otherPayables" class="mc" disabled></el-input>
                  </el-form-item>
                  <!-- </div> -->
                  <div class="topbutton2">
                    <el-form-item label="非流动负债合计:">
                      <el-input v-model="formLabelAlign.depositReceived" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="应付账款:">
                      <el-input v-model="formLabelAlign.accountsPayable" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="应付工资:">
                      <el-input v-model="formLabelAlign.wagesPayable" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="应付税金:">
                      <el-input v-model="formLabelAlign.taxesPayable" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </div>
            <div class="from3">

              <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign" class="f3">
                <div class="fl">
                  <div class="topbutton">
                    <el-form-item label="资产总额:" id="bs">
                      <el-input v-model="formLabelAlign.totalNonCurrentAssets" class="mc3" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton">
                    <el-form-item label="非流动资产合计:" class="mgtop" id="bs">
                      <el-input v-model="formLabelAlign.totalNonCurrentAssets" class="mc3" disabled></el-input>
                    </el-form-item>
                  </div>
                  <el-form-item label="长期投资:" id="bs">
                    <el-input v-model="formLabelAlign.permanentInvestment" class="mc3" disabled></el-input>
                  </el-form-item>
                  <!-- </div> -->
                  <div class="topbutton2">
                    <el-form-item label="固定资产净值:" id="bs">
                      <el-input v-model="formLabelAlign.netValueOfFixedAssets" class="mc3" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="无形资产:" id="bs">
                      <el-input v-model="formLabelAlign.intangibleAssets" class="mc3" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="长期待摊费用:" id="bs">
                      <el-input v-model="formLabelAlign.longTermUnamortizedExpenses" class="mc3" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="递延税款借项:" id="bs">
                      <el-input v-model="formLabelAlign.deferredTaxesDebit" class="mc3" disabled></el-input>
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </div>
            <div class="from3">
              <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" class="f3_2">
                <div class="fl f1_class2">
                  <div class="topbutton">
                    <el-form-item label="负债和股东权益合计:" class="mgtop">
                      <el-input v-model="formLabelAlign.constructionInProcess" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton">
                    <el-form-item label="股东权益合计:" class="mgtop">
                      <el-input v-model="formLabelAlign.totalShareholdersEquity" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <el-form-item label="实收资本:">
                    <el-input v-model="formLabelAlign.paiclUpCapital" class="mc" disabled></el-input>
                  </el-form-item>
                  <div class="topbutton2">
                    <el-form-item label="资本公积:">
                      <el-input v-model="formLabelAlign.capitalReserve" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="盈余公积:">
                      <el-input v-model="formLabelAlign.earnedSurplus" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="未分配利润:">
                      <el-input v-model="formLabelAlign.undistributedProfit" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                  <div class="topbutton2">
                    <el-form-item label="资产总额:">
                      <el-input v-model="formLabelAlign.totalAssets" class="mc" disabled></el-input>
                    </el-form-item>
                  </div>
                </div>
              </el-form>

            </div>
          </div>
          <div class="dk3_2">
            <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" class="f2">
              <div class="fl f1_class3">
                <div class="topbutton">
                  <el-form-item label="主营业收入:">
                    <el-input v-model="formLabelAlign.mainBusinessIncome" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <!-- <div class="topbutton2"> -->
                <el-form-item label="主营业成本:">
                  <el-input v-model="formLabelAlign.mainBusinessCost" class="mc" disabled></el-input>
                </el-form-item>
                <!-- </div> -->
                <div class="topbutton2">
                  <el-form-item label="主营业税金:">
                    <el-input v-model="formLabelAlign.mainBusinessTax" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2">
                  <el-form-item label="主营业利润:">
                    <el-input v-model="formLabelAlign.mainBusinessProfits" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2">
                  <el-form-item label="营业费用:">
                    <el-input v-model="formLabelAlign.operatingExpense" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2">
                  <el-form-item label="管理费用:">
                    <el-input v-model="formLabelAlign.administrationExpense" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2">
                  <el-form-item label="财务费用:">
                    <el-input v-model="formLabelAlign.financialExpense" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2">
                  <el-form-item label="营业利润:">
                    <el-input v-model="formLabelAlign.operatingProfit" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2">
                  <el-form-item label="投资损益:">
                    <el-input v-model="formLabelAlign.profitAndLossOnInvestments" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2">
                  <el-form-item label="营业外收入:">
                    <el-input v-model="formLabelAlign.nonbusinessIncome" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2">
                  <el-form-item label="利润总额:">
                    <el-input v-model="formLabelAlign.totalProfit" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2">
                  <el-form-item label="所得税:">
                    <el-input v-model="formLabelAlign.incomeTax" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="topbutton2" id="b">
                  <el-form-item label="净利润:">
                    <el-input v-model="formLabelAlign.retainedProfits" class="mc" disabled></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <div class="dk4">
          <div class="dk4_1">
            <el-form :label-position="labelPosition" label-width="110px" :model="formLabelAlign" class="f4">
              <div class="f4_1">
                <div class="ygrs" id="gs">
                  <el-form-item label="员工人数:">
                    <el-input v-model="formLabelAlign.numberOfEmployees" class="inputk" disabled></el-input>
                  </el-form-item>
                </div>
                <div id="gs" style="float:left">
                  <el-form-item label="核心员工人数:">
                    <el-input v-model="formLabelAlign.numberOfCoreEmployees" class="inputk" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="ygrs2" id="gs">
                  <el-form-item label="主营业务:" :inline="true" style="flex-wrap:wrap" class="in">
                    <el-input v-model="formLabelAlign.mainBusinesses[0]" class="inputk" disabled></el-input>
                    <el-input v-model="formLabelAlign.mainBusinesses[1]" class="inputk2" disabled></el-input>
                    <el-input v-model="formLabelAlign.mainBusinesses[2]" class="inputk2" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="ygrs2" id="gs">
                  <el-form-item label="国际业务描述:" class="in">
                    <el-input v-model="internationalBusinessDescription" class="inputk" disabled></el-input>
                    <el-input v-model="formLabelAlign.internationalBusinessDescriptions[0]" class="inputk2" disabled></el-input>
                    <el-input v-model="formLabelAlign.internationalBusinessDescriptions[1]" class="inputk3" disabled></el-input>
                  </el-form-item>
                </div>
                <div class="ygrs3" id="gs">
                  <el-form-item label="核心竞争力:" class="in" prop="coreCompetences">
                    <!-- <el-checkbox-group v-model="formLabelAlign.coreCompetences">
                                            <el-checkbox v-for="(item) in coreCompetencesList" :key="item.index" :label="item.label" disabled>{{item.value}}</el-checkbox>
                                        </el-checkbox-group> -->
                    <span v-for="item in coreCompetencesList" :key="item.index">{{item}}</span>
                  </el-form-item>
                </div>
                <div id="gs">
                  <el-form-item label="业务展望:">
                    <!-- <el-select v-model="value" placeholder="请选择" class="inputk" disabled>
                                            <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select> -->
                    <el-input v-model="theBusinessOutlook" class="inputk" disabled></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <div class="dk5">
          <div class="dk5_1">
            <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" class="f5">
              <div class="c">
                <div class="qscwjzl">下载文件资料</div>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" class="check_box_class">
                  <el-tooltip v-for="(city,index) in cities" :key="index" style="width:auto" class="item" effect="light" :content="city.fileName" placement="top"
                    popper-class="popper_upload_list_class_special">
                    <el-checkbox :label="city.index">{{city.fileName}}</el-checkbox>
                  </el-tooltip>
                </el-checkbox-group>
                <div class="xzwj">
                  <el-button class="anniu" @click="downloadFile()">下载文件</el-button>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </el-card>
  </div>

</template>

<script>
import { creditOptimizationList, uploads, submitOrder, getOrderList } from "@/api/supplier.js";
import { mixins1 } from "@/mixins/index";
import { downloadExport } from "@/api/api.js";
// const cityOptions = this.cities;
export default {
  mixins: [mixins1],
  data() {
    return {
      checkAll: false,
      checkedCities: [],
      cities: [],
      // citiesName:['next.png'],
      isIndeterminate: false,
      fileList: [],
      name: "",
      value1: '',
      labelPosition: 'right',
      checkedCities2: [],
      theBusinessOutlook: "",
      internationalBusinessDescription: "",
      formLabelAlign: {

      },
      entName: "商安信(上海)企业发展股份有限公司",
      year: 2010,
      from: {
        reportUrl: '',
        ratResult: '',
        ratResults: ''
      },
      from2: {

      },
      MoreConsultation: '人名币',
      formLabelAligns: {
        years: ""
      },
      coreCompetencesList: [
      ],
      upLoadFileNumber: '',
      upLoadFileNumberIndex: [],
      upLoadFileNumberName: [],
      itemName: [],
      fileNameCheck: [],
      file: [],
      submitButton: true,
      disabledShow: true,
      uploadFormShow: true,
      orderNum: '',
      files: [],
      fileNames: []
    }

  },
  created() {
    let a = localStorage.getItem("row")
    let b = JSON.parse(a)
    this.from = b
    console.log(this.from.orderNum)
    this.orderNum = this.from.orderNum
    console.log(this.$route.query)
  },
  mounted() {
    this.getData()
    this.getOrder()
  },
  methods: {
    async getOrder() {
      let res = await getOrderList('orderNum=' + this.orderNum + '&orderType=' + '' + '&orderState=' + '' + '&createDateS=' + '' + '&createDateE=' + '' +
        '&finishDateS=' + '' + '&finishDateE=' + '')
      res.data.records.forEach((item, index) => {
        this.from = item
        this.files = item.reportUrl
      })
      if (this.from.ratResult && this.files) {
        this.disabledShow = false
        this.uploadFormShow = false
      }
      else if (this.from.ratResult && !this.files) {
        this.$nextTick(() => {
          this.disabledShow = false
          this.uploadFormShow = true
        })
      }
      else if (!this.from.ratResult && this.files) {
        this.disabledShow = true
        this.uploadFormShow = false
      }
      else {
        this.disabledShow = true
        this.uploadFormShow = true
      }
      if (this.from.ratResult && this.files) {
        console.log(this.from.ratResult)
        this.submitButton = false
      } else if (this.from.ratResult && this.file.length > 0) {
        console.log(this.from.ratResult)
        console.log(this.file)
        this.submitButton = false
      }
      else {
        this.submitButton = true
      }
    },
    async tijiao() {
      this.from.reportUrl = this.file
      this.from.reportNames = this.fileNames
      let res = await submitOrder(this.from)
      if (res && res.code == 200) {
        this.$message({
          message: '提交信息成功!',
          type: 'success'
        })
        this.getOrder()
      }
    },
    async getData() {
      let data = await creditOptimizationList("entName=" + this.from.subjectName + '&year=' + this.$route.query.years);
      this.formLabelAlign = data.data
      if (data.data.coreCompetencesCN != null || data.data.coreCompetencesCN != []) {
        this.coreCompetencesList = data.data.coreCompetencesCN
      }
      else {
        this.coreCompetencesList = []
      }
      this.upLoadFileNumber = data.data.fileUrl
      this.cities = data.data.index
      console.log(data.data)
      this.theBusinessOutlook = data.data.theBusinessOutlook
      if (this.formLabelAlign.internationalBusinessDescription) {
        this.internationalBusinessDescription = "是"
      } else {
        this.internationalBusinessDescription = "否"
      }
      this.formLabelAligns.years = this.$route.query.years
      this.formLabelAlign.totalCurrentAssets = this.filter(this.formLabelAlign.totalCurrentAssets)
      this.formLabelAlign.monetaryCapital = this.filter(this.formLabelAlign.monetaryCapital)
      this.formLabelAlign.accountsReceivable = this.filter(this.formLabelAlign.accountsReceivable)
      this.formLabelAlign.advancePayment = this.filter(this.formLabelAlign.advancePayment)
      this.formLabelAlign.stock = this.filter(this.formLabelAlign.stock)
      this.formLabelAlign.unamortizedExpense = this.filter(this.formLabelAlign.unamortizedExpense)
      this.formLabelAlign.totalCurrentLiability = this.filter(this.formLabelAlign.totalCurrentLiability)
      this.formLabelAlign.otherPayables = this.filter(this.formLabelAlign.otherPayables)
      this.formLabelAlign.depositReceived = this.filter(this.formLabelAlign.depositReceived)
      this.formLabelAlign.accountsPayable = this.filter(this.formLabelAlign.accountsPayable)
      this.formLabelAlign.wagesPayable = this.filter(this.formLabelAlign.wagesPayable)
      this.formLabelAlign.taxesPayable = this.filter(this.formLabelAlign.taxesPayable)
      this.formLabelAlign.totalAssets = this.filter(this.formLabelAlign.totalAssets)
      this.formLabelAlign.totalNonCurrentAssets = this.filter(this.formLabelAlign.totalNonCurrentAssets)
      this.formLabelAlign.permanentInvestment = this.filter(this.formLabelAlign.permanentInvestment)
      this.formLabelAlign.netValueOfFixedAssets = this.filter(this.formLabelAlign.netValueOfFixedAssets)
      this.formLabelAlign.intangibleAssets = this.filter(this.formLabelAlign.intangibleAssets)
      this.formLabelAlign.longTermUnamortizedExpenses = this.filter(this.formLabelAlign.longTermUnamortizedExpenses)
      this.formLabelAlign.deferredTaxesDebit = this.filter(this.formLabelAlign.deferredTaxesDebit)
      this.formLabelAlign.constructionInProcess = this.filter(this.formLabelAlign.constructionInProcess)
      this.formLabelAlign.totalShareholdersEquity = this.filter(this.formLabelAlign.totalShareholdersEquity)
      this.formLabelAlign.paiclUpCapital = this.filter(this.formLabelAlign.paiclUpCapital)
      this.formLabelAlign.capitalReserve = this.filter(this.formLabelAlign.capitalReserve)
      this.formLabelAlign.earnedSurplus = this.filter(this.formLabelAlign.earnedSurplus)
      this.formLabelAlign.undistributedProfit = this.filter(this.formLabelAlign.undistributedProfit)
      this.formLabelAlign.totalAssets = this.filter(this.formLabelAlign.totalAssets)
      this.formLabelAlign.mainBusinessIncome = this.filter(this.formLabelAlign.mainBusinessIncome)
      this.formLabelAlign.mainBusinessCost = this.filter(this.formLabelAlign.mainBusinessCost)
      this.formLabelAlign.mainBusinessTax = this.filter(this.formLabelAlign.mainBusinessTax)
      this.formLabelAlign.mainBusinessProfits = this.filter(this.formLabelAlign.mainBusinessProfits)
      this.formLabelAlign.operatingExpense = this.filter(this.formLabelAlign.operatingExpense)
      this.formLabelAlign.administrationExpense = this.filter(this.formLabelAlign.administrationExpense)
      this.formLabelAlign.financialExpense = this.filter(this.formLabelAlign.financialExpense)
      this.formLabelAlign.operatingProfit = this.filter(this.formLabelAlign.operatingProfit)
      this.formLabelAlign.profitAndLossOnInvestments = this.filter(this.formLabelAlign.profitAndLossOnInvestments)
      this.formLabelAlign.nonbusinessIncome = this.filter(this.formLabelAlign.nonbusinessIncome)
      this.formLabelAlign.totalProfit = this.filter(this.formLabelAlign.totalProfit)
      this.formLabelAlign.incomeTax = this.filter(this.formLabelAlign.incomeTax)
      this.formLabelAlign.retainedProfits = this.filter(this.formLabelAlign.retainedProfits)
    },
    handleChange(file, fileList) {
      console.log(file.name);
      console.log(this.fileList);
      console.log(fileList[0].name);
      console.log(fileList.name);
      this.name = file.name;
      console.log(this.name);
    },
    handleCheckAllChange(val, value) {
      const all = this.cities.map(item => {
        return item.index
      })
      this.checkedCities = val ? all : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    handleUploadFile1(params) {
      if (!this.from.report) {
        const file = params.file
        console.log(file, 485);
        let from = new FormData();
        from.append("files", file);
        uploads(from).then(res => {
          this.file.push(res[0])
          this.fileNames.push(params.file.name)
        })
      }
    },
    deleteUpload(index) {
      this.file.splice(index, 1)
    },
    // 下载文件
    downloadFile() {
      this.upLoadFileNumberIndex = []
      this.upLoadFileNumberName = []
      this.checkedCities.forEach((item1, index1) => {
        this.upLoadFileNumber.forEach((item2, index2) => {
          if (item1 == index2 + 1) {
            this.upLoadFileNumberIndex.push(item2)
          }
        })
        this.cities.forEach((item, index) => {
          if (item1 == index + 1) {
            this.upLoadFileNumberName.push(item.fileName)
          }
        })
      })
      this.upLoadFileNumberIndex.forEach((item, index) => {
        var link = document.createElement("a");
        link.setAttribute("download", this.upLoadFileNumberName[index]);
        link.href = item;
        link.click()
      })
    },
    // 评级服务下载报告
    async loadingFile() {
      console.log(this.$route.query.id)
      let res = await downloadExport('id=' + this.$route.query.id)
      res.data.url.forEach((item, index) => {
        var link = document.createElement("a");
        link.setAttribute("download", res.data.urlName[index]);
        // link.href = item;
        link.href = this.jpg + item;
        link.click()
      })
    },
  }
}
</script>

<style  scoped>
  /* 下载报告 */
  .download_button {
    position: absolute;
    right: -260px;
    top: 0;
    width: 160px;
    height: 40px;
    margin-top: -20px;
    color: #339c9b;
    background: #ffffff;
    border-radius: 32px;
    border: 1px solid #e1e7ea;
  }
  .download_button.el-button--default:hover,
  .download_button.el-button--default:focus {
    color: #339c9b;
  }
  .download_button span {
    font-size: 16px;
  }
  .download_button i {
    margin-right: 6px;
    font-size: 18px;
  }
  .scbg {
    position: relative;
  }
  ::v-deep .dk3 .el-form-item__content {
    display: flex;
  }
  ::v-deep .year .el-form-item__content {
    display: flex;
  }
  ::v-deep .f1_class2 .el-form-item__label {
    width: 148px !important;
  }
  ::v-deep .f1_class3 .el-form-item__label {
    width: 85px !important;
  }
  /* ::v-deep .f1_class2 .el-input{
      width: 260px !important;
    }
    ::v-deep .f1_class2 .el-input__inner{
      width: 260px !important;
    } */
  ::v-deep .f1_class1 .el-form-item__label {
    width: 116px !important;
  }
  ::v-deep .check_box_class .el-checkbox__input {
    top: -46px;
  }
  ::v-deep .check_box_class .el-checkbox__label {
    width: 120px;
    height: 40px;
    margin-bottom: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ::v-deep .el-textarea__inner:focus {
    border-color: #dcdfe6;
  }
  ::v-deep .el-textarea__inner:hover {
    border-color: #dcdfe6;
  }
  .add_invoice_messages li {
    float: left;
    width: 60px;
    height: 60px;
    /* margin-top: 30px; */
    margin-right: 30px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .add_invoice_messages ul {
    margin-top: 70px;
  }
  .reportUrl_class ul {
    margin-top: 10px;
  }
  /* .reportUrl_class li{
        margin-top: 0 !important;
    } */
  .close_icon {
    position: absolute;
    top: -6px;
    left: 52px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fb6476;
  }
  .close_icon i {
    position: relative;
    top: -4px;
    left: 2px;
    font-size: 8px;
    color: #ffffff;
  }
  ::v-deep .el-upload__tip {
    left: 66px;
  }
  .invoiceUrlClass {
    margin-left: 71px;
    /* margin-top: 60px; */
  }
  .limit_width {
    padding-top: 100px !important;
  }
  .orderdetail {
    width: 1500px;
    height: auto;
    padding-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(12, 79, 71, 0.15);
    border-radius: 10px;
    margin-bottom: 40px;
  }
  .orderdetail_main_header {
    float: left;
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 30px;
  }
  .in span {
    display: inline-block;
    margin-right: 40px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 21px;
  }
  .orderdetail_main_header > div {
    float: left;
  }
  .orderdetail_main .orderscentre {
    width: 72px;
    height: 22px;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 40px;
    margin-left: 20px;
    margin-top: 30px;
    float: left;
  }
  .orderdetail_main_header .orderdetail_anniu {
    width: 160px;
    height: 40px;
    background: #339c9b;
    border-radius: 25px;
    margin-left: 1130px;
    color: #ffffff;
  }
  .orderdetail_main_header .orderdetail_annius {
    width: 160px;
    height: 40px;
    background: #dae4e9;
    border-radius: 25px;
    margin-left: 1130px;
    color: #ffffff;
  }
  .orderdetail_orderscentre {
    width: 150px;
    height: 22px;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #162747;
    margin-bottom: 9px;
    margin-top: 9px;
  }
  .orderdetail_form {
    width: 1440px;
    height: auto;
    padding-bottom: 20px;
    background: #f7fafc;
    border-radius: 12px;
    float: left;
    margin-left: 30px;
  }
  .orderdetail_form_nav {
    float: left;
    margin-left: 20px;
    margin-top: 30px;
  }
  /deep/ .orderdetail_form_nav .el-input__inner {
    width: 242px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  >>> .orderdetail .el-form-item__content {
    width: 242px;
    height: 40px;
    float: left;
  }
  >>> .orderdetail .el-form-item__label {
    width: 60px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 10px;
  }
  >>> .orderdetail .el-form-item {
    float: left;
    width: 312px;
  }
  >>> #nav {
    margin-left: 50px;
    float: left;
  }
  .orderdetail .scbg {
    float: left;
    margin-top: 30px;
  }
  >>> .orderdetail .el-upload {
    width: 60px;
    height: 60px;
  }

  >>> .orderdetail .scbg .el-form-item__content {
    height: 60px;
    width: 1000px;
  }
  >>> .scbg .el-upload-list {
    display: none;
  }
  >>> .orderdetail .scbg .el-upload {
    padding: 0;
    margin: 0;
    float: left;
    border: 1px dashed #e1e7ea;
  }
  >>> .orderdetail .scbg .el-form-item__label {
    width: 60px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-bottom: 28px;
    margin-top: 8px;
    margin-right: 10px;
  }
  >>> .orderdetail .scbg .el-upload--picture-card i {
    width: 20px;
    height: 20px;
    bottom: 44px;
  }
  >>> .orderdetail .scbg .tishi {
    width: 276px;
    height: 16px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #92a2bc;
    line-height: 16px;
    float: left;
    margin-top: 44px;
    margin-left: 10px;
  }
  >>> .orderdetail .scbg .el-form-item {
    width: 1140px;
  }
  .orderdetail .pjjg {
    float: left;
    margin-top: 30px;
  }
  >>> .orderdetail .pjjg .el-form-item__label {
    width: 60px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-bottom: 68px;
    margin-top: 10px;
    margin-right: 10px;
  }
  >>> .orderdetail .pjjg .el-textarea {
    width: 1330px;
    height: 100px;
    background: #ffffff;
    border-radius: 4px;
    float: left;
  }
  >>> .orderdetail .pjjg .el-textarea__inner {
    width: 1330px;
    height: 100px;
    float: left;
  }
  >>> .c .el-checkbox:last-of-type {
    margin-top: 18px;
  }

  >>> .xzwj .anniu {
    width: 160px;
    height: 40px;
    background: #339c9b;
    border-radius: 25px;
    color: #ffffff;
    margin-top: 30px;
  }
  >>> .c .el-checkbox__label {
    margin-bottom: 30px;
    background: #ffffff;
    border-radius: 4px;
    text-align: center;
    line-height: 26px;
    margin-left: 8px;
    padding: 10px 17px 10px 17px;
  }
  >>> .qscwjzl + .el-checkbox:last-of-type .el-checkbox__label {
    border-radius: 4px;
    text-align: center;
    line-height: 26px;
    margin-left: 8px;
    padding: 10px 17px 10px 17px;
    background: #f7fafc;
  }
  >>> .c .el-checkbox,
  .el-checkbox__input {
    display: inline;
    margin-right: 60px;
  }
  >>> .c .el-checkbox:last-of-type + div {
    margin: 22px 0 !important;
  }
  @media screen and (max-width: 1664px) {
    /* 下载报告 */
    .scbg .download_button {
      position: absolute;
      right: -170px;
      top: 0;
      width: 106px;
      height: 26px;
      margin-top: -13px;
      color: #339c9b;
      background: #ffffff;
      border-radius: 22px;
      border: 1px solid #e1e7ea;
    }
    .scbg .download_button span {
      font-size: 12px;
    }
    .scbg .download_button i {
      margin-right: 4px;
      font-size: 12px;
    }
    ::v-deep .f1_class1 .el-form-item__label {
      width: 87px !important;
    }
    ::v-deep .f1_class3 .el-form-item__label {
      width: 63px !important;
    }
    ::v-deep .f1_class2 .el-form-item__label {
      width: 111px !important;
    }
    ::v-deep .check_box_class .el-checkbox__input {
      /* margin-bottom: 78px; */
      top: -28px;
    }
    .limit_width {
      padding-top: 66px !important;
    }
    .orderdetail {
      width: 1000px;
      height: auto;
      padding-bottom: 20px;
      background: #ffffff;
      border-radius: 6px;
      margin-bottom: 26px;
    }
    .orderdetail_main_header {
      float: left;
      margin-bottom: 13px;
      margin-top: 20px;
      margin-left: 20px;
    }
    .orderdetail_main_header > div {
      float: left;
    }
    .orderdetail_main .orderscentre {
      width: 48px;
      height: 14px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 26px;
      margin-left: 13px;
      margin-top: 20px;
      float: left;
    }
    .orderdetail_main_header .orderdetail_anniu {
      width: 106px;
      height: 26px;
      background: #339c9b;
      border-radius: 16px;
      margin-left: 753px;
      line-height: 0px;
      color: #ffffff;
    }
    .orderdetail_main_header .orderdetail_annius {
      width: 106px;
      height: 26px;
      background: #dae4e9;
      border-radius: 16px;
      margin-left: 753px;
      line-height: 0px;
      color: #ffffff;
    }
    .in span {
      display: inline-block;
      margin-right: 26px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 14px;
    }
    .orderdetail_orderscentre {
      width: 100px;
      height: 14px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #162747;
      margin-bottom: 6px;
      margin-top: 6px;
    }
    .orderdetail_form {
      width: 960px;
      height: auto;
      padding-bottom: 13px;
      background: #f7fafc;
      border-radius: 8px;
      float: left;
      margin-left: 20px;
    }
    .orderdetail_form_nav {
      float: left;
      margin-left: 13px;
      margin-top: 20px;
    }
    >>> .orderdetail_form_nav .el-input__inner {
      width: 150px;
      height: 26px;
      border-radius: 2px;
      border: 1px solid #e1e7ea;
    }
    >>> .orderdetail .el-form-item__content {
      width: 150px;
      height: 26px;
      float: left;
    }
    >>> .orderdetail .el-form-item__label {
      width: 51px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-right: 6px;
    }
    >>> .orderdetail .el-form-item {
      float: left;
      width: 208px;
    }
    >>> #nav {
      margin-left: 33px;
      float: left;
    }
    .orderdetail .scbg {
      float: left;
      margin-top: 20px;
    }
    >>> .orderdetail .el-upload {
      width: 40px;
      height: 40px;
    }
    >>> .orderdetail .scbg .el-form-item__content {
      height: 40px;
      width: 666px;
    }
    >>> .scbg .el-upload-list {
      display: none;
    }
    >>> .orderdetail .scbg .el-upload {
      padding: 0;
      margin: 0;
      float: left;
      border: 1px dashed #e1e7ea;
    }
    >>> .orderdetail .scbg .el-form-item__label {
      width: 51px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 18px;
      margin-bottom: 18px;
      margin-top: 5px;
      margin-right: 6px;
    }
    >>> .orderdetail .scbg .el-upload--picture-card i {
      width: 13px;
      height: 13px;
      bottom: 54px;
    }
    >>> .orderdetail .scbg .tishi {
      width: 204px;
      height: 10px;
      font-size: 8px;
      font-family: MicrosoftYaHei;
      color: #92a2bc;
      line-height: 10px;
      float: left;
      margin-top: 29px;
      margin-left: 6px;
    }
    >>> .orderdetail .scbg .el-form-item {
      width: 760px;
    }
    .orderdetail .pjjg {
      float: left;
      margin-top: 20px;
    }
    >>> .orderdetail .pjjg .el-form-item__label {
      width: 51px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-bottom: 45px;
      margin-top: 6px;
      margin-right: 6px;
    }
    >>> .orderdetail .pjjg .el-textarea {
      width: 886px;
      height: 66px;
      background: #ffffff;
      border-radius: 2px;
      float: left;
    }
    >>> .orderdetail .pjjg .el-textarea__inner {
      width: 875px;
      height: 66px;
      float: left;
    }
    >>> .c .el-checkbox:last-of-type {
      margin-top: 12px;
    }
    >>> .el-textarea .el-input__count {
      right: 20px;
    }
    >>> .xzwj .anniu {
      width: 106px;
      height: 26px;
      background: #339c9b;
      border-radius: 16px;
      color: #ffffff;
      margin-top: 20px;
    }
    ::v-deep .check_box_class .el-checkbox__label {
      width: 80px;
      height: 26px;
      margin-bottom: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    >>> .c .el-checkbox__label {
      background: #ffffff;
      border-radius: 2px;
      text-align: center;
      line-height: 17px;
      margin-left: 5px;
      padding: 6px 11px 6px 11px;
    }
    >>> .qscwjzl + .el-checkbox:last-of-type .el-checkbox__label {
      border-radius: 2px;
      text-align: center;
      line-height: 17px;
      margin-left: 5px;
      padding: 6px 11px 6px 11px;
      background: #f7fafc;
    }
    >>> .c .el-checkbox,
    .el-checkbox__input {
      display: inline;
      margin-right: 40px;
    }
    >>> .c .el-checkbox:last-of-type + div {
      margin: 14px 0 !important;
    }
  }

  .topbutton {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .date-picker-icon {
    width: 230px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .second_title_box {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .second_title1 {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #162747;
  }
  .f3_2 {
    width: 460px;
    height: 460px;
    background: #f7fafc;
    border-radius: 12px;
    margin-right: 30px;
    margin-top: 30px;
  }
  .c {
    margin-left: 20px;
  }
  .ygrs {
    margin-bottom: 20px;
    margin-top: 30px;
    float: left;
  }
  .ygrs2 {
    margin-top: 20px;
    float: left;
    width: 1140px;
  }
  .ygrs3 {
    margin-top: 28px;
    margin-bottom: 28px;
    float: left;
  }
  >>> .mc .el-input__inner {
    width: 100%;
    /* width: 308px; */
    height: 40px;
  }
  >>> .inputk .el-input__inner {
    width: 404px;
    height: 40px;
  }
  >>> .inputk2 .el-input__inner {
    width: 404px;
    height: 40px;
  }
  >>> .inputk3 .el-input__inner {
    width: 404px;
    height: 40px;
  }
  >>> .gdpgzx_select .el-input .el-input__inner {
    width: 230px;
    height: 40px;
  }
  >>> .year .date-picker-icon .el-input__inner {
    width: 230px;
    height: 40px;
  }
  .anniu {
    width: 160px;
    height: 40px;
    background-color: #339b9a;
  }
  .dinput {
    width: 1100px;
    display: flex;
    float: left;
    justify-content: space-between;
    align-items: center;
  }
  .mgtop {
    margin-top: 20px;
  }
  .f {
    width: 460px;
    height: 400px;
    background: #f7fafc;
    border-radius: 12px;
    margin-right: 30px;
    margin-top: 20px;
  }
  .gdpgzx_select {
    width: 230px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .inputk {
    width: 404px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    float: left;
  }
  .inputk2 {
    width: 404px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    margin-left: 31px;
    float: left;
  }
  >>> .in .el-form-item__content {
    width: 1340px;
  }
  .inputk3 {
    width: 404px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    margin-left: 31px;
    margin-right: 20px;
  }
  .f2 {
    width: 460px;
    height: 890px;
    background: #f7fafc;
    border-radius: 12px;
    margin-top: 20px;
  }
  .dxk {
    margin-top: 5px;
  }
  .f3 {
    width: 460px;
    height: 460px;
    background: #f7fafc;
    border-radius: 12px;
    margin-top: 30px;
    margin-right: 30px;
  }
  .f4 {
    width: 1440px;
    height: 400px;
    background: #f7fafc;
    border-radius: 12px;
    margin-left: 30px;
  }
  .f4_1 {
    margin-left: 20px;
  }
  .fl {
    margin-left: 20px;
    margin-right: 20px;
  }
  .in {
    width: 1000px;
    float: left;
  }

  .tb i {
    width: 20px;
    height: 14px;
  }
  .topbutton2 {
    margin-top: 20px;
  }
  .dk3_1 {
    width: 980px;
    height: 913px;
    display: flex;
    flex-wrap: wrap;
  }
  >>> .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 16px;
    padding: 0;
    margin-right: 10px;
  }
  /deep/#bs .el-form-item__label {
    width: 116px !important;
    height: 24px;
    font-size: 16px;
    padding: 0;
    margin-right: 10px;
  }
  >>> .mc3 .el-input__inner {
    width: 294px;
    height: 40px;
  }

  >>> .el-input__prefix {
    margin-left: 200px;
  }
  >>> .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
  .a {
    width: 1500px;
    height: auto;
    padding-bottom: 30px;
    /* height: 1748px; */
    background: #ffffff;
    display: flex;
    border-radius: 10px;
    margin: 0 auto;
  }

  >>> .el-card__body {
    padding: 0;
  }
  >>> .el-form-item {
    padding: 0;
    margin: 0;
    /* width: 450px; */
  }
  >>> .el-input__inner {
    margin-right: 20px;
  }
  .from3 {
    display: flex;
  }

  .dk3 {
    width: 1440px;
    display: flex;
    float: left;
    margin-left: 30px;
    height: 911px;
  }

  .mc {
    width: 100%;
    /* width: 308px; */
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .mc2 {
    width: 294px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .mc3 {
    width: 294px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
  }
  .dk3_2 {
    display: flex;
    width: 450px;
    height: 920px;
  }
  .dk4 {
    float: left;
    height: 400px;
    margin-top: 30px;
  }
  .dk4_1 {
    height: 399px;
    display: flex;
    float: left;
    align-items: center;
    flex-wrap: wrap;
  }
  .dk5 {
    height: auto;
    /* height: 278px; */
    float: left;
    margin-top: 30px;
  }
  .dk5_1 {
    height: auto;
    /* height: 278px; */
    float: left;
  }
  .qscwjzl {
    margin-top: 30px;
    font-size: 16px;
  }
  .heard {
    margin-top: 30px;
  }
  .gdpgzx {
    float: left;
    margin-left: 30px;
  }
  .year {
    float: left;
    margin-left: 40px;
  }
  .cgbutton {
    float: right;
    margin-right: 30px;
  }
  .djsc2 {
    margin-bottom: 50px;
  }
  >>> .el-upload-list {
    display: flex;
    flex-wrap: nowrap;
  }
  >>> .el-upload-list__item {
    float: left;
    width: 120px;
    height: 40px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #142746;
    line-height: 35px;
    margin-top: 0px;
    border-radius: 4px;
    margin-left: 30px;
    background: #f7fafc;
  }
  >>> .el-upload {
    width: 161px;
    height: 45px;
    margin-top: 20px;
  }
  >>> .el-upload-list__item:first-child {
    margin: 0;
  }
  >>> .el-upload-list__item-name {
    float: left;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #142746;
    line-height: 26px;
    margin-left: 17px;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .yscwj {
    width: 116px;
    height: 24px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 21px;
    margin-top: 50px;
  }
  >>> #gs .el-form-item {
    padding: 0;
    margin: 0;
    width: 1420px;
  }
  >>> .el-upload-list__item-name [class^='el-icon'] {
    display: none;
  }
  .f5 {
    width: 1440px;
    height: auto;
    padding-bottom: 30px;
    /* height: 278px; */
    background: #f7fafc;
    border-radius: 12px;
    margin-left: 30px;
    float: left;
  }
  .el-checkbox__input.is-checked >>> el-checkbox__inner {
    background-color: red;
    border-color: red;
  }

  .djsc {
    width: 160px;
    height: 40px;
    background: #339c9b;
    border-radius: 32px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 21px;
  }
  @media screen and (max-width: 1664px) {
    .topbutton {
      margin-bottom: 13px;
      margin-top: 20px;
    }
    .date-picker-icon {
      width: 153px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .yscwj {
      width: 77px;
      height: 16px;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 14px;
      margin-top: 33px;
    }
    .second_title_box {
      margin-top: 13px;
      margin-bottom: 20px;
    }
    .second_title1 {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #162747;
    }
    .f3_2 {
      width: 306px;
      height: 306px;
      background: #f7fafc;
      border-radius: 8px;
      margin-right: 20px;
      margin-top: 20px;
    }
    .c {
      margin-left: 13px;
    }
    .ygrs {
      margin-bottom: 13px;
      margin-top: 20px;
    }
    .ygrs2 {
      margin-top: 13px;
      float: left;
      width: 760px;
    }
    .ygrs3 {
      margin-top: 18px;
      margin-bottom: 18px;
    }
    .anniu {
      width: 106px;
      height: 26px;
      background-color: #339b9a;
    }

    .dinput {
      width: 733px;
      display: flex;
      float: left;
      justify-content: space-between;
      align-items: center;
    }
    .mgtop {
      margin-top: 13px;
    }
    .f {
      width: 306px;
      height: 266px;
      background: #f7fafc;
      border-radius: 8px;
      margin-right: 20px;
      margin-top: 13px;
    }
    .gdpgzx_select {
      width: 153px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .inputk {
      width: 269px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .inputk2 {
      width: 269px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
      margin-left: 20px;
    }
    .inputk3 {
      width: 269px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
      margin-left: 20px;
      margin-right: 13px;
    }
    .f2 {
      width: 306px;
      height: 593px;
      background: #f7fafc;
      border-radius: 8px;
      margin-top: 13px;
    }
    .dxk {
      margin-top: 3px;
    }
    .f3 {
      width: 306px;
      height: 306px;
      background: #f7fafc;
      border-radius: 8px;
      margin-top: 20px;
      margin-right: 20px;
    }
    .f4 {
      width: 960px;
      height: 266px;
      background: #f7fafc;
      border-radius: 8px;
      margin-left: 20px;
    }
    .f4_1 {
      margin-left: 13px;
    }
    .fl {
      margin-left: 13px;
      margin-right: 13px;
    }
    .tb i {
      width: 13px;
      height: 9px;
    }
    .topbutton2 {
      margin-top: 13px;
    }
    .dk3_1 {
      width: 652px;
      height: 608px;
      display: flex;
      flex-wrap: wrap;
    }
    .a {
      width: 1000px;
      height: auto;
      padding-bottom: 20px;
      /* height: 1165px; */
      background: #ffffff;
      display: flex;
      border-radius: 6px;
      margin: 0 auto;
    }
    .dk3 {
      width: 960px;
      display: flex;
      float: left;
      margin-left: 20px;
      height: 606px;
    }
    .mc {
      width: 100%;
      /* width: 198px; */
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .mc2 {
      width: 86px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .mc3 {
      width: 186px;
      height: 26px;
      background: #ffffff;
      border-radius: 2px;
    }
    .dk3_2 {
      display: flex;
      width: 300px;
      height: 613px;
    }
    .dk4 {
      float: left;
      height: 266px;
      margin-top: 20px;
    }
    .dk4_1 {
      height: 266px;
      display: flex;
      float: left;
      align-items: center;
      flex-wrap: wrap;
    }
    .dk5 {
      height: auto;
      /* height: 185px; */
      float: left;
      margin-top: 20px;
    }
    .dk5_1 {
      height: auto;
      /* height: 185px; */
      float: left;
    }
    .qscwjzl {
      margin-top: 20px;
      font-size: 10px;
    }
    .heard {
      margin-top: 20px;
    }
    .gdpgzx {
      float: left;
      margin-left: 20px;
    }
    .year {
      float: left;
      margin-left: 26px;
    }
    .cgbutton {
      float: right;
      margin-right: 20px;
    }
    .djsc2 {
      margin-bottom: 33px;
    }
    .f5 {
      width: 960px;
      height: auto;
      /* height: 185px; */
      background: #f7fafc;
      border-radius: 8px;
      margin-left: 20px;
      float: left;
    }
    .gdpgzx span {
      font-size: 9px;
    }
    .year span {
      font-size: 9px;
    }
    .in {
      width: 950px;
    }
    .djsc {
      width: 106px;
      height: 26px;
      background: #339c9b;
      border-radius: 21px;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 14px;
    }
    >>> #gs .el-form-item {
      padding: 0;
      margin: 0;
    }

    >>> .el-input__prefix {
      margin-left: 130px;
    }
    >>> .el-form-item__label {
      width: 75px !important;
      height: 16px;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 25px;
      padding: 0;
      margin-right: 6px;
    }
    >>> .el-card__body {
      padding: 0;
    }
    >>> .limit_width {
      width: 1000px;
      height: auto;
      margin: 0 auto;
      padding-top: 96px;
      padding-bottom: 26px;
    }
    >>> .el-checkbox__label {
      display: inline-block;
      padding-left: 6px;
      line-height: 12px;
      font-size: 8px;
    }
    >>> .el-form-item {
      padding: 0;
      margin: 0;
      /* width: 390px; */
    }
    >>> .el-upload-list {
      display: flex;
      flex-wrap: nowrap;
      margin-top: 20px;
    }
    >>> .el-upload {
      width: 72px;
      height: 30px;
      margin-top: 13px;
    }
    >>> .el-button {
      width: 78px;
      height: 17px;
      background: #339c9b;
      border-radius: 14px;
      font-size: 6px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 9px;
    }
    >>> .el-upload-list__item {
      float: left;
      width: 80px;
      height: 26px;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #142746;
      line-height: 23px;
      margin-top: 0px;
      border-radius: 2px;
      margin-left: 20px;
      background: #f7fafc;
    }
    >>> .el-upload-list__item:first-child {
      margin: 0;
    }
    >>> .el-upload-list__item-name {
      float: left;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #142746;
      line-height: 17px;
      margin-left: 11px;
      margin-top: 6px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
    >>> .el-upload-list__item-name [class^='el-icon'] {
      display: none;
    }
    >>> .el-button {
      line-height: 0;
    }
    /deep/#bs .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 25px;
      padding: 0;
      margin-right: 6px;
      float: left;
    }
    >>> .mc3 .el-input__inner {
      width: 186px;
      height: 26px;
    }
    >>> .mgtop .el-form-item__content {
      /* width: 186px; */
      height: 26px;
      /* float: left; */
    }
    >>> .el-form-item__content {
      margin-left: 0px !important;
      /* width: 198px; */
      height: 26px;
      /* float: left; */
    }

    >>> .in .el-form-item__content {
      width: 970px;
    }

    >>> .mc .el-input__inner {
      width: 100%;
      /* width: 198px; */
      height: 26px;
    }
    >>> .inputk .el-input__inner {
      width: 269px;
      height: 26px;
    }
    >>> .inputk2 .el-input__inner {
      width: 269px;
      height: 26px;
    }
    >>> .inputk3 .el-input__inner {
      width: 269px;
      height: 26px;
    }
    >>> .gdpgzx_select .el-input .el-input__inner {
      width: 153px;
      height: 26px;
    }
    >>> .year .date-picker-icon .el-input__inner {
      width: 153px;
      height: 26px;
    }
    ::v-deep .el-upload__tip {
      left: -45px;
    }
    .invoiceUrlClass {
      margin-left: 57px;
      /* margin-top: 60px; */
    }
    .add_invoice_messages li {
      float: left;
      width: 40px;
      height: 40px;
      /* margin-top: 20px; */
      margin-right: 20px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .add_invoice_messages ul {
      margin-top: 60px;
    }
    .reportUrl_class ul {
      margin-top: 6px;
    }
    .close_icon {
      position: absolute;
      top: -6px;
      left: 32px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fb6476;
      transform: scale(0.8);
    }
    .close_icon i {
      position: relative;
      top: -4px;
      left: 2px;
      font-size: 8px;
      color: #ffffff;
    }
  }
</style>